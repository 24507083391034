<div class="modal-wrapper p-6" [formGroup]="resourceForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Resource" : "Add Resource" }}
      </h2>
      <p class="textXS mt-2 text">
        {{ isEditMode ? "Update details" : "Enter details for add Resource" }}
      </p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <input formControlName="resourceID" type="hidden" />
  <div class="content-wrapper my-4">
    <div class="col s12 m6 l6">
      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Resource Name <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            appCapitalizeFirstLetter
            matInput
            placeholder="Enter here"
            formControlName="name"
          />
        </mat-form-field>
        <div
          *ngIf="resourceForm.get('name')?.touched || resourceForm.get('name')?.dirty"
        >
          <div
            *ngIf="resourceForm.get('name')?.hasError('whitespace')"
            class="required-star mt-1"
          >
            Field cannot be empty or contain only whitespace.
          </div>
          <div
            *ngIf="!resourceForm.get('name')?.hasError('whitespace') && resourceForm.get('name')?.hasError('invalidNameSpecialChar')"
            class="required-star mt-1"
          >
            Field cannot contain special characters.
          </div>
          <div
            *ngIf="!resourceForm.get('name')?.hasError('whitespace') && resourceForm.get('name')?.hasError('invalidName')"
            class="required-star mt-1"
          >
            Invalid Name.
          </div>
        </div>
      </div>
      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Email Address <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            id="email"
            matInput
            placeholder="Enter here"
            formControlName="email"
          />
        </mat-form-field>
        <span
          class="required-star"
          *ngIf="
                  resourceForm.get('email')?.invalid &&
                  resourceForm.get('email')?.touched
                "
        >
          <span *ngIf="resourceForm.get('email')?.errors?.['required']">
            Field is required.
          </span>
          <span *ngIf="resourceForm.get('email')?.errors?.['email']">
            Email is not valid.
          </span>
        </span>
      </div>
      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Phone Number <span class="required-star">*</span>
        </p>
        <div class="d-flex finance-detail-wrapper">
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            class="country-select"
          >
            <mat-select
              placeholder="Code"
              Country
              formControlName="countryCode"
            >
              <mat-option value="+91">+91</mat-option>
              <mat-option value="+1">+1</mat-option>
              <mat-option value="+61">+61</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              id="phone"
              matInput
              placeholder="Enter here"
              formControlName="phone"
              minlength="6"
              maxlength="10"
              appNumericInput
              (numericInputValidityChange)="handleNumericInputValidityChange('phone', $event)"
            />
          </mat-form-field>
        </div>
        <span class="required-star" *ngIf="!numericInputValidity['phone']">
          Please enter a numeric value.
        </span>
      </div>

      <div class="col s12 m6 l6 mb-4">
        <div class="input-wrapper">
          <p class="mat-body-1 text mb-1 input-label input-info">
            Currency <span class="required-star">*</span>
          </p>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            aria-placeholder="select"
            [style.cursor]="isEditMode ? 'not-allowed' : 'pointer'"
          >
            <mat-select placeholder="Select" formControlName="currency">
              <mat-option
                *ngFor="let currency of filteredCurrencies"
                [value]="currency.cr_currency"
                >{{ currency.cr_currency }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="
              resourceForm.get('currency')?.invalid &&
              (resourceForm.get('currency')?.touched ||
                resourceForm.get('currency')?.dirty)
            "
          >
            Field is required.
          </span>
        </div>
      </div>

      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Resource Pricing/Per Month
          <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            maxlength="9"
            placeholder="Enter here"
            formControlName="salary"
            appNumericInput
            (numericInputValidityChange)="
          handleNumericInputValidityChange('salary', $event)"
          />
        </mat-form-field>
        <span class="required-star" *ngIf="!numericInputValidity['salary']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            resourceForm.get('salary')?.invalid &&
            (resourceForm.get('salary')?.touched ||
              resourceForm.get('salary')?.dirty) && numericInputValidity
          "
        >
          Fiels is required.
        </span>
      </div>
      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Role <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input matInput placeholder="Enter here" formControlName="role" />
        </mat-form-field>
        <div
          *ngIf="resourceForm.get('role')?.touched || resourceForm.get('role')?.dirty"
        >
          <span
            class="required-star"
            *ngIf="resourceForm.get('role')?.hasError('required')"
          >
            Field is required.
          </span>
          <div
            class="required-star mt-1"
            *ngIf="!resourceForm.get('role')?.hasError('required') && resourceForm.get('role')?.hasError('whitespace')"
          >
            Field cannot be empty or contain only whitespace.
          </div>
        </div>
      </div>
      <!-- <div class="col s12 m6 l6">
        <div class="input-wrapper">
          <p class="mat-body-1 text mb-1 input-label">Start Date</p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="dd/mm/yyyy"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <span
            class="required-star"
            *ngIf="
              resourceForm.get('startDate')?.invalid &&
              (resourceForm.get('startDate')?.touched ||
                resourceForm.get('startDate')?.dirty)
            "
          >
            Field is required.
          </span>
        </div>
      </div> -->
    </div>
    <div class="modal-footer mt-2 d-flex justify-end">
      <sft-button
        [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
        [label]="isEditMode ? 'Update' : 'Save'"
        [color]="'primary'"
        [variant]="'flat'"
        [disableRipple]="false"
        [size]="'large'"
        [disable]="isButtonDisabled"
        (click)="isButtonDisabled ? null : onSubmit()"
      ></sft-button>
    </div>
  </div>
</div>
